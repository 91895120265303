/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/jquery-lazy@1.7.10/jquery.lazy.min.js
 * - /npm/jquery-lazy@1.7.10/jquery.lazy.plugins.min.js
 * - /npm/sweetalert2@9.17.4/dist/sweetalert2.all.min.js
 * - /npm/jquery.marquee@1.5.0/jquery.marquee.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
